// import Cookies from 'js-cookie'

const TokenKey = "vue_token";
const name = "userName";
const avatar = "userAvatar";
const menu = "menu";
const site = "site";
const bMix = "bMix";
const siteType = "siteType";
export function getToken() {
  return localStorage.getItem(TokenKey);
}
export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}
export function getName() {
  return localStorage.getItem(name);
}
export function setName(token) {
  return localStorage.setItem(name, token);
}
export function setAvatar(token) {
  return localStorage.setItem(avatar, token);
}
export function getAvatar() {
  return localStorage.getItem(avatar);
}
export function setMenus(token) {
  return localStorage.setItem(menu, token);
}
export function getMenus() {
  return localStorage.getItem(menu);
}

export function removeToken() {
  return localStorage.clear();
}
// 站点
export function setSite(id) {
  return localStorage.setItem(site, id);
}
export function getSite() {
  return localStorage.getItem(site);
}
// 拌合站|| 商混站

export function setBmix(id) {
  return localStorage.setItem(bMix, id);
}
export function getBmix() {
  return localStorage.getItem(bMix);
}

//  总站 1  站点 2

export function setSiteType(id) {
  return localStorage.setItem(siteType, id);
}
export function getSiteType() {
  return localStorage.getItem(siteType);
}
