<template>
  <div id="app">
    <div>
      <headers></headers>
    </div>

    <div id="box-content">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
#app {
  color: #b1cffc;
  padding: 0 22px;
  height: 100%;
}
#box-content {
  height: calc(100% - 170px);
  width: 100%;
}
</style>
<script>
import headers from "@/components/headerIndex";
export default {
  data() {
    return {};
  },
  mounted() {},
  components: {
    headers,
  },
};
</script>
