<template>
  <div class="home">
    <div class="left">
      <div class="left-top">
        <div class="left-top-searchTime mb-10 pr">
          <img
            class="pa h-100"
            src="../assets/image/border.png"
            style="width: 100%"
            alt=""
          />
          <div class="border-box-content pt-20 overflow h-100">
            <div>
              <el-date-picker
                @change="getDefaultData"
                v-model="dateTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 100%"
                size="mini"
                :picker-options="pickerOptions"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
              >
              </el-date-picker>
            </div>
            <div>
              <el-select
                @change="getDefaultData"
                v-model="spec"
                placeholder="规格"
                size="mini"
                style="width: 100%"
                filterable
              >
                <el-option
                  v-for="item in specList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="left-top-searchAddress mb-10 pr">
          <img
            class="pa h-100"
            src="../assets/image/border.png"
            style="width: 100%"
          />
          <div class="border-box-content pt-10 h-100 w-100 pr">
            <div style="text-align: center" class="pr">选择站点</div>
            <div style="display: flex; align-items: center; padding: 0 16px">
              <img
                src="../assets/image/lc.png"
                style="width: 36px; margin-right: 10px"
                alt=""
              />
              <el-cascader
                :options="sectionList"
                :disabled="sideType === '2'"
                v-model="value"
                @change="getDefaultData"
                :props="{
                  checkStrictly: true,
                  label: 'name',
                  children: 'children',
                  value: 'value',
                }"
                style="width: 100%"
              ></el-cascader>
              <!--              <div v-else>{{ sectionList }}</div>-->

              <!--              <el-select v-model="value" placeholder="标段" style="width: 100%">-->
              <!--                <el-option-->
              <!--                  v-for="item in selects"-->
              <!--                  :key="item.value"-->
              <!--                  :label="item.label"-->
              <!--                  :value="item.value"-->
              <!--                >-->
              <!--                </el-option>-->
              <!--              </el-select>-->
            </div>
          </div>
        </div>
        <div class="left-top-pie mb-10">
          <img src="../assets/image/border.png" style="width: 100%" alt="" />
          <div class="h-100 d-flex flex-column pt-20">
            <div class="pr z100 mb-20 d-flex" style="padding: 0 16px">
              <span>累计卸货吨重</span>
              <span style="margin-left: auto">{{ pieTotal }}</span>
            </div>
            <div
              id="pie"
              class=""
              ref="pie"
              style="height: 100%; width: 100%"
            ></div>
          </div>

          <!--          <dv-border-box-8 style="padding: 5px">-->

          <!--          </dv-border-box-8>-->
        </div>
      </div>
      <div class="left-bottom pr">
        <img
          class="pa h-100"
          src="../assets/image/border.png"
          style="width: 100%"
        />
        <div class="pt pr pt-20 overflow h-100">
          <div style="text-align: center">累计卸货排行</div>
          <div class="unLoadList">
            <dv-scroll-ranking-board
              :config="config"
              style="width: 100%; height: 200px"
            />
            <!--            <div
              class="unLoadList-row"
              v-for="(item, index) in rankList"
              :key="index"
            >
              <div class="unLoadList-row-top">
                <div class="unLoadList-row-top-left">
                  <span>NO.{{ index + 1 }}</span>
                  <span>{{ item.label }}</span>
                </div>
                <div class="unLoadList-row-top-right">
                  <span>{{ item.value }}</span>
                  <span style="color: rgba(230, 247, 255, 0.5)">吨</span>
                </div>
              </div>
              <div class="unLoadList-row-bottom">
                <el-progress
                  :percentage="50"
                  :show-text="false"
                  :stroke-width="8"
                  define-back-color="rgba(255,255,255,0.1)"
                  color="#1890FF"
                ></el-progress>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-top pr">
        <img
          class="pa h-100"
          src="../assets/image/border.png"
          style="width: 100%"
        />
        <div style="padding: 10px" class="pr h-100 w-100">
          <div id="container" class="pr">
            <div class="totalSection pa z100" v-if="sideType === '1'">
              <img src="../assets/image/totalBox.png" width="100%" alt="" />
              <div>
                <div>{{ bmixData.length }}</div>
                <span>所属站点</span>
              </div>
            </div>
            <div class="totalSection pa z100">
              <img src="../assets/image/totalBox.png" width="100%" alt="" />
              <div>
                <div>{{ carNum || 0 }}</div>
                <span>拉货中车辆</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-bottom pr">
        <img
          class="pa h-100"
          src="../assets/image/border.png"
          style="width: 100%"
        />
        <div class="pr z100 h-100 w-100">
          <div
            ref="homeBottom"
            id="homeBottom"
            style="height: 100%; width: 100%"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.home {
  height: 100%;
  display: flex;
  .left {
    width: 20%;
    height: 100%;
    &-top {
      height: 70%;
      &-searchTime {
        height: 22%;

        .border-box-content {
          padding: 10px 20px;
          overflow: hidden;
          & > div:nth-child(1) {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #35527d;
          }
        }

        .el-range-editor .el-range-input {
          background-color: #032341;
        }
        .el-input__inner {
          border: none;
        }
      }
      &-searchAddress {
        height: calc(23% - 10px);
      }
      &-pie {
        height: calc(55% - 10px);
        position: relative;
        & > div {
          height: 100%;
        }
        & > img {
          height: 100%;
          position: absolute;
        }
      }
      .el-input.is-disabled .el-input__inner {
        background-color: #032341 !important;
        border: none;
      }
    }
    &-bottom {
      height: 30%;
      margin-top: 10px;
      .unLoadList {
        height: calc(100% - 52px);
        //overflow-y: auto;
        padding: 0 26px;
        /*   &-row {
          padding: 0 26px;
          font-size: 14px;
          color: #fff;
          margin-bottom: 18px;
          &-top {
            display: flex;
            justify-content: space-between;
            &-left {
              & > span:nth-child(2) {
                margin-left: 5px;
              }
            }
          }
          &-bottom {
            margin-top: 8px;
          }
        }*/
      }
      .unLoadList::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }

      .unLoadList::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 4px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #535353;
      }

      .unLoadList::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        background: #ededed;
      }
    }
  }
  .right {
    width: 80%;
    height: 100%;
    &-top {
      width: 100%;
      height: 70%;
      #container {
        width: 100%;
        height: 100%;
        padding: 1px;
        .amap-logo,
        .amap-copyright {
          display: none !important;
        }
        .totalSection {
          width: 132px;
          height: 120px;
          left: 31px;
          top: 27px;
          & > div {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 43px;
            color: #ff848a;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            width: 100%;
            & > span {
              color: #aee4f8;
              font-size: 14px;
              margin-top: 20px;
              margin-bottom: 10px;
            }
          }
        }
        .totalSection:nth-child(2) {
          transform: translateX(100%);
          left: 56px;
        }
      }
    }
    &-bottom {
      margin-top: 10px;
      height: 30%;
    }
  }
}
</style>
<script>
import { getfilterTitle, getPanelData, location } from "@/api/panel";
// @ is an alias to /src
import AMap from "AMap";
import AMapUI from "AMapUI";
import { getSiteType, getToken } from "@/utils/auth";
// import AMapUI from 'AMapUI'
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      options: {},
      value: "",
      spec: "",
      dateTime: [],
      selects: [],
      rankList: [],
      pieData: {},
      sectionList: [],
      specList: [],
      pieTotal: "0.00",
      bmixData: [],
      carsData: [],
      lineChart: [],
      config: {
        data: [],
        unit: "吨",
        rowNum: 3,
        sort: false,
      },
      timeout: "",
      sideType: 1,
      carNum: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "所有数据",
            onClick(picker) {
              picker.$emit("pick", ["", ""]);
            },
          },
          {
            text: "近7天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近30天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  beforeDestroy() {
    clearInterval(this.timeout);
  },
  beforeMount() {},
  mounted() {
    let sitesData = {};
    if (this.$route.query.y) {
      sitesData.type = this.$route.query.y;
      sitesData.token = this.$route.query.t;
    } else {
      sitesData.type = getSiteType();
      sitesData.token = getToken();
    }
    this.sideType = sitesData.type;
    console.log(window.location.origin);
    console.log(sitesData);
    this.$store.dispatch("apiType/changeSiteType", sitesData).then(() => {
      if (this.$route.query.y) {
        // window.location.href = 'http://www.baidu.com'
        window.location.href = window.location.origin;
        // location.href = 'baidu.com';
        return;
      }
      this.createMap();
      getfilterTitle().then((res) => {
        if (this.sideType === "1") {
          this.sectionList = res.data.section;
          this.value = [res.data.section[0].value];
        } else {
          this.sectionList = [{ name: res.data.section, value: "0" }];
          this.value = "0";
        }
        res.data.spec.unshift({ label: "规格", value: "" });
        this.specList = res.data.spec;

        this.getDefaultData();
      });
    });
    // this.getEcharts3DBar();

    // this.getEcharts3DBar();
  },

  methods: {
    // 获取默认数据
    getDefaultData() {
      console.log(this.dateTime);
      getPanelData({
        start_time: this.dateTime ? this.dateTime[0] : "",
        end_time: this.dateTime ? this.dateTime[1] : "",
        section_id: this.value[this.value.length - 1],
        spec: this.spec,
      }).then((res) => {
        try {
          this.pointSimplifierIns.setData([]);
          this.pathSimplifierIns.setData();
        } catch (e) {
          console.log(e);
        }
        this.config = { data: [], unit: "吨", rowNum: 3, sort: false };
        this.rankList = res.data.unloadinfo_rank;
        for (const item of res.data.unloadinfo_rank) {
          let obj = {};
          obj.name = item.label;
          obj.value = Number(parseFloat(item.value).toFixed(2));
          this.config.data.push(obj);
        }
        this.config = { ...this.config };
        this.pieData = res.data.pie_chart;
        this.pieTotal = res.data.pie_chart.unload_num_total;
        this.initPieChart();
        this.bmixData = res.data.map.bmix;
        this.carsData = res.data.map.car;
        this.lineChart = res.data.bar_chart;
        this.carNum = res.data.in_transit_num;
        setTimeout(() => {
          this.initPage();
          this.initChart();
          this.createCarsPath();
        }, 500);
      });
    },
    // 地图创建
    createMap() {
      this.map = new AMap.Map("container", {
        zoom: 10,
        zooms: [3, 20],
        // center: [108.950084, 34.291814],
        center: [108.950084, 34.291814],
        mapStyle: "amap://styles/3e9298470dd45c3283820b2f9451f8f7",
      });
      let _self = this;
      AMap.plugin(["AMap.Scale"], function () {
        // _self.map.addControl(new AMap.ToolBar())
        // _self.map.addControl(new AMap.Scale())
      });
      // eslint-disable-next-line no-undef
      AMapUI.loadUI(["misc/PointSimplifier"], function (PointSimplifier) {
        if (!PointSimplifier.supportCanvas) {
          alert("当前环境不支持 Canvas！");
          return;
        }

        //启动页面
        // _self.initPage(PointSimplifier)
        _self.PointSimplifier = PointSimplifier;
      });
      AMapUI.load(
        ["ui/misc/PathSimplifier", "lib/$"],
        function (PathSimplifier) {
          _self.PathSimplifier = PathSimplifier;
        }
      );
      AMapUI.loadUI(["overlay/SimpleMarker"], function (SimpleMarker) {
        _self.SimpleMarker = SimpleMarker;
      });
    },
    // 初始化地图数据
    initPage() {
      let that = this;
      let pointSimplifierIns, groupStyleMap;
      pointSimplifierIns = new that.PointSimplifier({
        zIndex: 0,
        autoSetFitView: true,
        map: this.map, //所属的地图实例

        getPosition: function (item) {
          return item.position;
        },
        getHoverTitle: function () {
          return;
        },
        //使用GroupStyleRender
        renderConstructor: that.PointSimplifier.Render.Canvas.GroupStyleRender,
        renderOptions: {
          pointHardcoreStyle: {
            content: "none",
            width: 1,
            height: 1,
            lineWidth: 1,
            fillStyle: null,
            strokeStyle: null,
          },
          //点的样式
          pointStyle: {
            width: 5,
            height: 5,
            fillStyle: "#A2D0FA",
          },
          pointHoverStyle: {
            content: "none",
          },
          getGroupId: function (item) {
            return item.groupId;
          },
          groupStyleOptions: function (gid) {
            return groupStyleMap[gid];
          },
        },
      });

      that.pointSimplifierIns = pointSimplifierIns;
      // pointMouseover pointMouseout
      // var text
      pointSimplifierIns.on("pointMouseover ", (event, point) => {
        let markerContent = `
        <div class="dispatch-mouserBox">
          <div class="dispatch-mouserBox-title">
<!--            <span>当前进行中订单数</span>-->
            <span>${point.data.data.label}</span>
          </div>
          <div class="dispatch-mouserBox-content">
<!--            <div>${
          point.data.data.type === 1 ? "砂石料场" : "商混站"
        }</div>-->
            <div  style="display:${
              this.sideType === "2" ? "none" : ""
            }">所属标段：${point.data.data.section}</div>
            <div>地址：${point.data.data.address}</div>
          </div>
        </div>
        `;
        if (point.data.type === 1) {
          markerContent = `
        <div class="dispatch-mouserBox">
          <div class="dispatch-mouserBox-title">
            <span>${point.data.data.label}</span>
            <span style="color:${point.data.data.status_color}">${point.data.data.status_name}</span>
          </div>
          <div class="dispatch-mouserBox-content">
            <div>司机id：${point.data.data.driverid}</div>
            <div>司机订单编号：${point.data.data.value}</div>
            <div>当前所在地：${point.data.data.address}</div>
          </div>
        </div>
        `;
        }

        that.marker = new AMap.Marker({
          position: point.data.position,
          anchor: "bottom-left",
          // 将 html 传给 content
          content: markerContent,
          // 以 icon 的 [center bottom] 为原点
          offset: new AMap.Pixel(-13, -30),
        });
        that.map.add(that.marker);
      });
      pointSimplifierIns.on("pointMouseout ", () => {
        that.map.remove(that.marker);
        // 创建纯文本标记
      });
      pointSimplifierIns.on("pointClick ", (event, point) => {
        that.map.remove(that.marker);
        pointSimplifierIns.setData([]);
        if (!this.searchRouter) {
          this.input = point.data.data.name;
        }
        that.activeId = point.data.data.key;
        this.initPage();
      });

      function onIconLoad() {
        // pointSimplifierIns.renderLater()
        pointSimplifierIns.render();
      }

      function onIconError() {
        console.log("图片加载失败！");
      }

      groupStyleMap = {
        0: {
          pointStyle: {
            //绘制点占据的矩形区域
            content: that.PointSimplifier.Render.Canvas.getImageContent(
              require("@/assets/image/lc.png"),
              onIconLoad,
              onIconError
            ),

            //宽度
            width: 36,
            //高度
            height: 53,
            //定位点为中心
            offset: ["-50%", "-50%"],
            fillStyle: null,
            zIndex: 8,
            //strokeStyle: null
          },
        },
        1: {
          pointStyle: {
            //绘制点占据的矩形区域
            content: that.PointSimplifier.Render.Canvas.getImageContent(
              require("@/assets/image/car.png"),
              onIconLoad,
              onIconError
            ),
            //宽度
            width: 33,
            //高度
            height: 38,
            //定位点为中心
            offset: ["-50%", "-50%"],
            fillStyle: null,
            zIndex: 8,
            // strokeStyle: null
          },
        },
      };

      //站点图标
      const data = [];
      for (const item of that.bmixData) {
        let obj = {
          position: [
            item.lon,
            item.lat,
            // item.lat_lng[item.lat_lng.length - 1].lng,
            // item.lat_lng[item.lat_lng.length - 1].lat,
          ],
          groupId: 0,
          data: item,
          type: 0,
        };
        data.push(obj);
      }

      this.searchRouteLine = false;
      pointSimplifierIns.setData(data);
    },
    // 柱状图初始化
    initChart() {
      // 初始化echart
      this.chart = this.$echarts.init(this.$refs.homeBottom, "tdTheme");
      const data = [];
      let label = this.lineChart.map((item) => {
        data.push(item.value);
        return item.label;
      });
      // const sideData = data.map((item) => item + 90);
      const colorArr = ["#174776", "#34A3E0", "#4DD7F9"];
      const color = {
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: colorArr[0],
          },
          {
            offset: 0.5,
            color: colorArr[0],
          },
          {
            offset: 0.5,
            color: colorArr[1],
          },
          {
            offset: 1,
            color: colorArr[1],
          },
        ],
      };
      const barWidth = 30;
      this.options = {
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var str = "规格" + "：" + params[0].name;
            params.filter(function (item) {
              if (item.componentSubType == "bar") {
                str += "<br/>" + item.seriesName + "：" + item.value;
              }
            });
            return str;
          },
        },
        grid: {
          x: "7%",
          x2: "0%",
          y: "15%",
          y2: "15%",
        },
        legend: {
          show: false,
          textStyle: {
            color: "#fff",
            fontSize: "20",
          },
        },
        xAxis: {
          data: label,
          //坐标轴
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "#214776",
            },
            textStyle: {
              color: "#fff",
              fontSize: "10",
            },
          },
          type: "category",
          axisLabel: {
            textStyle: {
              color: "#C5DFFB",
              fontWeight: 500,
              fontSize: "14",
            },
          },
          axisTick: {
            textStyle: {
              color: "#fff",
              fontSize: "16",
            },
            show: false,
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          //坐标轴
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: "#214776",
            },
            textStyle: {
              color: "#fff",
              fontSize: "10",
            },
          },
          axisTick: {
            show: false,
          },
          //坐标值标注
          axisLabel: {
            show: true,
            textStyle: {
              color: "#C5DFFB",
            },
          },
          //分格线
          splitLine: {
            lineStyle: {
              color: "#13365f",
            },
          },
        },
        series: [
          {
            z: 1,
            name: "吨数",
            type: "bar",
            barWidth: barWidth,
            barGap: "0%",
            data: data,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(48, 126, 142, 0.2)",
            },
            itemStyle: {
              normal: {
                color: color,
              },
            },
          },
          {
            z: 2,
            name: "吨数",
            type: "pictorialBar",
            data: data,
            symbol: "diamond",
            symbolOffset: ["-0%", "50%"],
            symbolSize: [barWidth, 10],
            itemStyle: {
              normal: {
                color: color,
              },
            },
            tooltip: {
              show: false,
            },
          },
          {
            z: 3,
            name: "吨数",
            type: "pictorialBar",
            symbolPosition: "end",
            data: data,
            symbol: "diamond",
            symbolOffset: ["0%", "-50%"],
            symbolSize: [barWidth - 4, (10 * (barWidth - 4)) / barWidth],
            itemStyle: {
              normal: {
                borderWidth: 2,
                color: colorArr[2],
              },
            },
            tooltip: {
              show: false,
            },
          },
        ],
      };
      this.chart.setOption(this.options, true);
      this.chart.resize();
      window.addEventListener("resize", () => {
        this.chart.resize();
      });
    },
    // 饼图初始化
    initPieChart() {
      let data = [
        { value: 1048, name: "Search Engine" },
        { value: 735, name: "Direct" },
        { value: 580, name: "Email" },
        { value: 484, name: "Union Ads" },
        { value: 300, name: "Video Ads" },
      ];
      if (this.pieData) {
        data = [
          { value: this.pieData.unload_num_no, name: "我没车" },
          { value: this.pieData.unload_num_find, name: "我要找车" },
          { value: this.pieData.unload_num_fixed, name: "包干订单" },
          { value: this.pieData.unload_num_unpaid, name: "固定车辆订单" },
        ];
      }
      this.charts = this.$echarts.init(this.$refs.pie);
      let options = {
        color: ["#4FD5A7", "#6BB6DE", "#4569E8", "#EBC13C"],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c}吨 <br/> 占比{d}%",
        },
        legend: {
          bottom: "5%",
          left: "center",
          borderRadius: 8,
          itemWidth: 16,
          itemHeight: 4,
          textStyle: {
            fontSize: 14,
            color: "#B2D5FF",
          },
        },
        textStyle: {
          fontSize: 15,
          fontFamily: "PingFang SC",
          // fontWeight:"bold"
        },
        toolbox: {
          show: true,
        },
        series: [
          {
            name: "累计卸货吨数",
            type: "pie",
            radius: [45, 80],
            center: ["50%", "43%"],
            data: data,
            label: {
              color: "#B5D5FC",
            },
            textStyle: {
              fontSize: 120,
            },
          },
        ],
      };
      this.charts.setOption(options, true);

      this.charts.resize();
      window.addEventListener("resize", () => {
        this.charts.resize();
      });
    },

    // 创建车辆
    createCarsPath() {
      const that = this;
      that.pathSimplifierIns = new that.PathSimplifier({
        zIndex: 114,
        //autoSetFitView:false,
        map: that.map, //所属的地图实例
        autoSetFitView: false,
        getPath: function (pathData) {
          return pathData.path;
        },
        getHoverTitle: function () {
          return;
        },
        renderOptions: {
          pathLineStyle: {
            dirArrowStyle: false,
          },
          // getPathStyle: function (pathItem, zoom) {
          getPathStyle: function () {
            // var color = colors[pathItem.pathIndex % colors.length],
            //     lineWidth = Math.round(4 * Math.pow(1.1, zoom - 3))

            return {
              keyPointHoverStyle: {
                fillStyle: null,
                strokeStyle: null,
              },
              pathLineStyle: {
                strokeStyle: null,
                lineWidth: 1,
                borderWidth: 1,
                borderStyle: null,
                dirArrowStyle: false,
              },
              pathNavigatorStyle: {
                pathLinePassedStyle: {
                  strokeStyle: null,
                  lineWidth: 1,
                  borderWidth: 1,
                  borderStyle: null,
                  dirArrowStyle: false,
                },
              },
              startPointStyle: {
                radius: 1,
                fillStyle: null,
                lineWidth: 1,
                strokeStyle: null,
              },
              endPointStyle: {
                radius: 1,
                fillStyle: null,
                lineWidth: 1,
                strokeStyle: null,
              },
              pathLineHoverStyle: {
                strokeStyle: null,
                lineWidth: 1,
                borderWidth: 1,
                borderStyle: null,
                dirArrowStyle: false,
              },
              pathLineSelectedStyle: {
                strokeStyle: null,
                lineWidth: 1,
                borderWidth: 1,
                borderStyle: null,
                dirArrowStyle: false,
              },
            };
          },
        },
      });
      that.endIdx = 0;
      that.carData = [];
      that.navg = [];
      // 创建路线
      that.mypath = [];
      that.carsData.forEach((item, i) => {
        that.mypath.push({
          name: "动态路线1",
          path: [that.carsData[i].path],
          data: item,
        });
        that.pathSimplifierIns.setData(that.mypath);
      });
      console.log(that.mypath);

      window.pathSimplifierIns = that.pathSimplifierIns;
      // 创建巡航器
      that.mypath.forEach((item, i) => {
        that.navg[i] = that.pathSimplifierIns.createPathNavigator(
          i, //关联第1条轨迹
          {
            // loop: true, //循环播放
            speed: 100,
            pathNavigatorStyle: {
              content: that.PathSimplifier.Render.Canvas.getImageContent(
                require("@/assets/image/car.png")
              ),
              //宽度
              width: 33,
              //高度
              height: 38,
              //定位点为中心
              offset: ["-50%", "-50%"],
              fillStyle: null,
            },
          }
        );
      });
      //批量启动巡航器
      that.mypath.forEach((item, i) => {
        that.navg[i].start();
      });
      // 鼠标悬浮车辆显示详细信息
      that.pathSimplifierIns.on("pointMouseover", function (e, info) {
        let markerContent = `
        <div class="dispatch-mouserBox">
          <div class="dispatch-mouserBox-title">
            <span>${info.pathData.data.label}</span>
            <span style="color:${info.pathData.status_color}">${info.pathData.data.status_name}</span>
          </div>
          <div class="dispatch-mouserBox-content">
            <div>司机id：${info.pathData.data.driverid}</div>
            <div>手机号：${info.pathData.data.phone}</div>
            <div>司机订单编号：${info.pathData.data.value}</div>
            <div>订单类型：${info.pathData.data.type_name}</div>
            <div>订单状态：${info.pathData.data.status_name}</div>
            <div>当前所在地：${info.pathData.data.address}</div>
          </div>
        </div>
        `;

        that.marker2 = new AMap.Marker({
          position: info.pathData.path[0],
          anchor: "bottom-left",
          // 将 html 传给 content
          content: markerContent,
          // 以 icon 的 [center bottom] 为原点
          offset: new AMap.Pixel(-13, -30),
        });
        that.map.add(that.marker2);
        //info.pathData 即是相关的轨迹数据，如果info.pointIndex >= 0，则表示由轨迹上的节点触发
      });
      that.pathSimplifierIns.on("pointMouseout", function () {
        that.map.remove(that.marker2);
      });
      that.timeout = setInterval(() => {
        that.carExpandPath();
      }, 30000);
    },
    // 动态添加车辆轨迹
    carExpandPath() {
      let _self = this,
        cursor = [],
        status = [],
        oldCarPlate = [];

      //获取当前所有车辆位置并进行暂存
      /*      let _self = this,
        cursor = [],
        status = [],
        oldCarPlate = [];*/
      let queryData = {
        car_plate: "",
        path: "",
        driver_no: "",
      };
      this.mypath.forEach((item) => {
        queryData.car_plate += item.data.label + ",";
        queryData.driver_no += item.data.value + ",";
        queryData.path += item.path[item.path.length - 1].toString() + ";";
      });
      queryData.section_id = this.value[this.value.length - 1];
      queryData.spec = this.spec;
      location(queryData).then((res) => {
        // console.log(res)
        let newCarsList = [];
        for (const re of res.data) {
          // 循环当前已渲染车辆重绘路径点
          let checkBool = false;
          _self.navg.forEach((item, i) => {
            cursor.push(item.getCursor().clone());
            status.push(item.getNaviStatus());
            // 重构路径航路数据
            if (_self.mypath[i].data.label === re.label) {
              checkBool = true;
              // _self.carData[i].path = [..._self.carsData[i].path, ...re.car_track]
              /*       re.car_track.forEach((item, index) => {
                console.log(item); // console.log(index)
                re.car_track[index] = [item.longitude, item.latitude];
                // console.log(re.car_track)
              });*/
              // _self.mypath[i].path = _self.carData[i].path.concat(re.car_track);
              _self.mypath[i].path = _self.mypath[i].path.concat(re.path);
              // console.log(_self.carData[i].path)
              oldCarPlate.push(_self.mypath);
              // console.log('path')
            }
          });
          if (!checkBool) {
            newCarsList.push(re);
          }
        }
        // 新建车辆路径
        console.log(newCarsList);
        if (newCarsList.length > 0) {
          const startIndex = _self.mypath.length;
          newCarsList.forEach((item, index) => {
            _self.mypath.push({
              name: "动态路线1",
              path: [newCarsList[index].path],
              data: item,
            });
            _self.pathSimplifierIns.setData(_self.mypath);
            _self.navg[startIndex + index] =
              _self.pathSimplifierIns.createPathNavigator(
                startIndex + index, //关联第1条轨迹
                {
                  // loop: true, //循环播放
                  speed: 100,
                  pathNavigatorStyle: {
                    content: _self.PathSimplifier.Render.Canvas.getImageContent(
                      require("@/assets/image/car.png")
                    ),
                    //宽度
                    width: 33,
                    //高度
                    height: 38,
                    //定位点为中心
                    offset: ["-50%", "-50%"],
                    fillStyle: null,
                  },
                }
              );
            _self.navg[startIndex + index].start();
          });
        }
        //延展路径
        _self.pathSimplifierIns.setData(_self.mypath);

        _self.mypath.forEach((item, i) => {
          //重新建立一个巡航器
          _self.navg[i] = _self.pathSimplifierIns.createPathNavigator(
            i, //关联第1条轨迹
            {
              // loop: true, //循环播放
              speed: 60,
              pathNavigatorStyle: {
                content: _self.PathSimplifier.Render.Canvas.getImageContent(
                  require("@/assets/image/car.png")
                ),
                //宽度
                width: 33,
                //高度
                height: 38,
                //定位点为中心
                // offset: ["-50%", "-50%"],
                fillStyle: null,
                autoRotate: false,
              },
            }
          );

          if (status[i] !== "stop") {
            _self.navg[i].start();
          }

          // _self.navg[i].start()

          //恢复巡航器的位置
          if (cursor[i].idx >= 0) {
            _self.navg[i].moveToPoint(cursor[i].idx, cursor[i].tail);
          }
        });
      });

      console.log(_self.navg);
    },
  },
};
</script>
<style lang="scss">
.dispatch-mouserBox {
  width: 420px;
  min-height: 163px;
  background: #071a43;
  box-shadow: inset 0px 0px 4px 1px rgba(131, 186, 254, 0.49);
  opacity: 1;
  border: 1px solid #22395e;
  padding: 16px;
  box-sizzing: border-box;
  color: #bedbff;

  &-title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
  }

  &-content {
    font-size: 16px;

    //& > div:nth-child(1) {
    //  font-size: 16px;
    //  color: #4d6d9c;
    //}

    & > div {
      margin-top: 12px;
    }
  }
}
</style>
