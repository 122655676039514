<template>
  <!--订单数据-->
  <div class="wrap_orderDataView">
    <!--筛选条件-->
    <div class="orderData_setting" v-if="type === 1">
      <!--规格-->
      <el-select
        v-model="value_specs"
        placeholder="规格"
        clearable="true"
        @change="changeSpecs"
        filterable
      >
        <el-option
          v-for="item in options_specs"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span class="line"></span>
      <!--卸货户名-->
      <el-select
        v-model="value_name"
        placeholder="卸货户名"
        clearable="true"
        @change="changeName"
        filterable
      >
        <el-option
          v-for="item in options_name"
          :key="item.value"
          :label="item.value == '' ? '卸货户名' : item.value"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span class="line"></span>
      <!--是否为进行中订单站点-->
      <div class="stateOrder">
        <span>有进行中订单站点</span>
        <el-checkbox @change="changeRadio"></el-checkbox>
      </div>
    </div>
    <div
      class="content"
      :style="{
        height: type === 1 ? 'calc(100% - 50px)' : '100%',
        paddingBottom: type === 1 ? '25px' : '0',
      }"
    >
      <div class="left">
        <!--筛选-->
        <div class="setting" v-if="type === 2">
          <!--规格-->
          <el-select
            v-model="value_specs"
            placeholder="规格"
            clearable="true"
            @change="changeSpecs"
            filterable
          >
            <el-option
              v-for="item in options_specs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span class="line"></span>
          <!--卸货户名-->
          <el-select
            v-model="value_name"
            placeholder="卸货户名"
            clearable="true"
            @change="changeName"
            filterable
          >
            <el-option
              v-for="item in options_name"
              :key="item.value"
              :label="item.value == '' ? '卸货户名' : item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!--选择站点-->
        <div class="top" :style="{ height: type === 1 ? '16%' : '15%' }">
          <div class="chooseAddress">
            <div>选择站点</div>
            <div>
              <img
                src="../assets/image/lc.png"
                style="width: 36px; margin-right: 10px"
                alt=""
              />
              <el-cascader
                v-if="type === 1"
                :options="options_section"
                v-model="value_section"
                @change="changeAddress"
                :props="{
                  checkStrictly: true,
                  label: 'name',
                  children: 'children',
                  value: 'value',
                }"
                clearable
                style="width: 100%"
              ></el-cascader>
              <div v-else>{{ options_section }}</div>
            </div>
          </div>
        </div>
        <!--订单列表-->
        <div class="orderList" :style="{ height: type === 1 ? '80%' : '75%' }">
          <div>进行中订单</div>
          <div class="list">
            <div class="item" v-for="(item, index) in listData" :key="index">
              <div class="info">
                <span>{{ item.label }}</span>
                <span
                  @click="clickOrderNo(item)"
                  @mouseenter="mouseEnterOrder(item)"
                  @mouseleave="mouseLeaveOrder"
                >
                  {{ item.value }}
                </span>
                <span>{{ item.proportion + "%" }}</span>
              </div>
              <el-progress
                :percentage="item.proportion"
                :show-text="false"
                define-back-color="#1a1e29"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- 地图 -->
        <div id="containers"></div>
        <!--地图左上角的数字-->
        <div class="nums">
          <div class="item" v-if="type !== 2">
            <span class="num">{{ own_site_num }}</span>
            <span class="type">下属站点</span>
          </div>
          <div class="item" v-if="type !== 2">
            <span class="num">{{ in_transit_site_num }}</span>
            <span class="type">有订单站点</span>
          </div>
          <div class="item ing">
            <span class="num">{{ in_transit_num }}</span>
            <span class="type">进行中订单</span>
          </div>
        </div>
        <!--鼠标悬浮订单列表的订单编号-->
        <div class="orderDetailModal" v-if="isShowModal">
          <div class="top">
            <span>{{ modalData.mineral_species }}</span>
            <span>剩余可接</span>
            <span>{{ modalData.remain_ton }}吨</span>
          </div>
          <div class="info">
            <div class="time">
              <span>下单时间</span>
              <span>{{ modalData.ordertime }}</span>
            </div>
            <div class="address">
              <div class="start item">
                <span>发</span>
                <span>{{ modalData.mine_name }}</span>
              </div>
              <div class="end item">
                <span>收</span>
                <span>{{ modalData.bmix_name }}</span>
              </div>
            </div>
            <div class="infoNum">
              <div>
                <span>下单：{{ modalData.total_num }}吨</span>
                <span>已接：{{ modalData.ton_num }}吨</span>
              </div>
              <div>
                <span>已装：{{ modalData.load_num }}吨</span>
                <span>已卸：{{ modalData.unload_num }}吨</span>
              </div>
            </div>
            <div class="state">
              <span>{{ modalData.fixed_trace_status }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
import AMapUI from "AMapUI";
import { getSetting, screen } from "@/api/orderData";
import store from "@/store";
export default {
  data() {
    return {
      // 规格下拉数据
      options_specs: [],
      // 所选规格
      value_specs: "",
      // 卸货户名下拉数据
      options_name: [],
      // 所选卸货户名
      value_name: "",
      // 标段下拉数据
      options_section: [],
      // 所选标段
      value_section: "",
      // 1勾选进行中站点 0不勾选
      typeState: 0,
      // 当前页面入口判断 1总站 2商混站
      type: 1,
      // 列表
      listData: [],
      // 地图标记点数据
      data: [],
      // 是否显示订单编号弹窗
      isShowModal: false,
      // 悬浮订单编号时的弹窗数据
      modalData: {},
      // 下属站点
      own_site_num: "",
      // 有订单站点
      in_transit_site_num: "",
      // 进行中订单
      in_transit_num: "",
    };
  },
  created() {
    this.getSets();
    this.type = Number(store.getters.sitesTyp);
  },
  methods: {
    // 条件筛选
    screenChange() {
      screen({
        section_id: this.value_section[this.value_section.length - 1] || "",
        spec: this.value_specs,
        unloadinfo: this.value_name,
        is_show: this.typeState,
      })
        .then((res) => {
          this.in_transit_site_num = res.data.in_transit_site_num;
          this.own_site_num = res.data.own_site_num;
          this.in_transit_num = res.data.in_transit_num;
          this.listData = res.data.order_list;
          this.data = res.data.bmix_list;
          this.initPage(this.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取头部筛选条件
    getSets() {
      getSetting()
        .then((res) => {
          res.data.spec.unshift({
            label: "规格",
            name: "全部",
            value: "",
          });
          this.options_specs = res.data.spec;
          this.options_section = res.data.section;
          res.data.unloadinfo.unshift({
            label: "卸货户名",
            value: "",
          });
          this.options_name = res.data.unloadinfo;
          this.value_section = [res.data.section[0].value];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 创建地图
    createMap() {
      this.map = new AMap.Map("containers", {
        zoom: 6,
        zooms: [3, 20],
        center: [108.950084, 34.291814],
        mapStyle: "amap://styles/3e9298470dd45c3283820b2f9451f8f7",
        viewMode: "3D",
        rotateEnable: false,
        pitchEnable: false,
      });
    },
    // 点击订单编号 跳转订单详情
    clickOrderNo(e) {
      this.$router.push({
        name: "OrderDetail",
        query: { id: e.value, type: e.type },
      });
    },
    // 初始化绘制地图标记点的组件
    initIconsComponent() {
      let _self = this;
      AMap.plugin(["AMap.Scale"], function () {});
      AMapUI.loadUI(["misc/PointSimplifier"], function (PointSimplifier) {
        if (!PointSimplifier.supportCanvas) {
          alert("当前环境不支持 Canvas！");
          return;
        }
        //启动页面
        _self.PointSimplifier = PointSimplifier;
      });
    },
    // 绘制地图标记点
    initPage(data) {
      var that = this,
        tempData = [],
        pointSimplifierIns,
        groupStyleMap;
      // 绘制方法
      pointSimplifierIns = new that.PointSimplifier({
        zIndex: 0,
        autoSetFitView: true,
        map: this.map,
        getPosition: function (item) {
          return item.position;
        },
        getHoverTitle: function () {
          return;
        },
        renderConstructor: that.PointSimplifier.Render.Canvas.GroupStyleRender,
        renderOptions: {
          pointHardcoreStyle: {
            content: "none",
            width: 1,
            height: 1,
            lineWidth: 1,
            fillStyle: null,
            strokeStyle: null,
          },
          //点的样式
          pointStyle: {
            width: 5,
            height: 5,
            fillStyle: "#A2D0FA",
          },
          pointHoverStyle: {
            content: "none",
          },
          getGroupId: function (item) {
            return item.groupId;
          },
          groupStyleOptions: function (gid) {
            return groupStyleMap[gid];
          },
        },
      });
      that.pointSimplifierIns = pointSimplifierIns;

      // 鼠标悬浮标记点
      pointSimplifierIns.on("pointMouseover ", (event, point) => {
        let markerContent = `
        <div class="map_mouserBox">
          <div class="name">${point.data.data.label}</div>
          <div class="map_mouserBox_content">
            <div style="display:${this.type === 2 ? "none" : ""}">所属标段：${
          point.data.data.section
        }</div>
            <div>地址：${point.data.data.address}</div>
            <div>进行中订单数：${point.data.data.in_transit_num}</div>
          </div>
        </div>`;
        that.marker = new AMap.Marker({
          position: point.data.position,
          anchor: "bottom-left",
          // 将 html 传给 content
          content: markerContent,
          // 以 icon 的 [center bottom] 为原点 x y
          offset: new AMap.Pixel(33, 135),
        });
        that.map.add(that.marker);
      });

      // 鼠标移出
      pointSimplifierIns.on("pointMouseout ", () => {
        that.map.remove(that.marker);
      });

      //  鼠标点击 跳转料场热力详情
      // pointSimplifierIns.on("pointClick ", (event, point) => {
      //   this.$store.commit("changeStockyardPowerDetail", point.data.data.id);
      //   this.$store.commit("changeMenu", 9);
      // });

      function onIconLoad() {
        pointSimplifierIns.render();
      }

      function onIconError() {
        alert("图片加载失败！");
      }

      // 地图标记点样式
      groupStyleMap = {
        1: {
          pointStyle: {
            //绘制点占据的矩形区域
            content: that.PointSimplifier.Render.Canvas.getImageContent(
              require("@/assets/image/redPoint.png"),
              onIconLoad,
              onIconError
            ),
            //宽度
            width: 36,
            //高度
            height: 47,
            //定位点为中心
            offset: ["-50%", "-50%"],
            fillStyle: null,
            // zIndex: 8,
            //strokeStyle: null
          },
        },
        2: {
          pointStyle: {
            //绘制点占据的矩形区域
            content: that.PointSimplifier.Render.Canvas.getImageContent(
             require("@/assets/image/yellowPoint.png"),
              onIconLoad,
              onIconError
            ),
            //宽度
            width: 36,
            //高度
            height: 47,
            //定位点为中心
            offset: ["-50%", "-50%"],
            fillStyle: null,
            // zIndex: 8,
            //strokeStyle: null
          },
        },
      };

      // 组合绘制标记点所需数据
      for (const item of data) {
        let obj = {
          position: [item.lon, item.lat],
          data: item,
          groupId: item.in_transit_status === 1 ? 1 : 2,
        };
        tempData.push(obj);
      }
      pointSimplifierIns.setData(tempData);
    },
    // 鼠标悬浮订单列表的订单编号
    mouseEnterOrder(e) {
      console.log(e);
      this.modalData = e;
      this.isShowModal = true;
    },
    // 鼠标离开订单列表的订单编号
    mouseLeaveOrder() {
      this.isShowModal = false;
    },
    // 切换规格
    changeSpecs() {
      console.log(this.value_specs);
      if (this.pointSimplifierIns) {
        this.pointSimplifierIns.setData([]);
      }
      this.screenChange();
    },
    // 切换户名
    changeName() {
      console.log(this.value_name);
      if (this.pointSimplifierIns) {
        this.pointSimplifierIns.setData([]);
      }
      this.screenChange();
    },
    // 切换有无进行中订单
    changeRadio(e) {
      console.log(e);
      if (this.pointSimplifierIns) {
        this.pointSimplifierIns.setData([]);
      }
      this.typeState = e ? 1 : 0;
      this.screenChange();
    },
    // 切换站点
    changeAddress() {
      console.log(this.value_section[this.value_section.length - 1]);
      if (this.pointSimplifierIns) {
        this.pointSimplifierIns.setData([]);
      }
      this.screenChange();
    },
  },
  mounted() {
    this.createMap();
    this.initIconsComponent();
    setTimeout(() => {
      this.screenChange();
    }, 200);
  },
};
</script>

<style lang="scss">
.wrap_orderDataView {
  height: 100%;
  // 筛选条件
  > .orderData_setting {
    width: 720px;
    height: 50px;
    padding: 0 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background-image: url("@/assets/image/settingBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    > .el-select {
      width: 24%;
      .el-input .el-icon-arrow-up::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        margin-top: 5px;
        border: 5px solid #83bafe;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: #83bafe;
        border-left-color: transparent;
      }
      .el-input__inner {
        background-color: #042646;
        border: none;
        color: #bedbff;
        &::placeholder {
          color: #bedbff;
        }
      }
    }
    > .line {
      width: 1px;
      height: 27px;
      background-color: #35527d;
      margin: 0 10px 0 15px;
    }
    > .stateOrder {
      margin-left: 20px;
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > span {
        font-size: 18px;
        color: #bedbff;
      }
      .el-checkbox {
        > .el-checkbox__input {
          > .el-checkbox__inner {
            background-color: transparent;
            border-color: #86b9ff;
          }
        }
      }
    }
  }
  > .content {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 50px);
    padding-bottom: 25px;
    > .left {
      width: 20%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > .setting {
        height: 7%;
        display: flex;
        align-items: center;
        background-image: url("@/assets/image/settingBg.png");
        background-repeat: no-repeat;
        background-size: 100% 92%;
        > .el-select {
          width: 50%;
          .el-input .el-icon-arrow-up::before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            margin-top: 5px;
            border: 5px solid #83bafe;
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: #83bafe;
            border-left-color: transparent;
          }
          .el-input__inner {
            background-color: #042646;
            border: none;
            color: #bedbff;
            &::placeholder {
              color: #bedbff;
            }
          }
        }
        > .line {
          width: 1px;
          height: 27px;
          background-color: #35527d;
          margin: 0 10px 0 15px;
        }
      }
      // 选择站点
      > .top {
        > .chooseAddress {
          height: 100%;
          padding-top: 15px;
          background: url("../assets/image/border.png") no-repeat;
          background-size: 100% 100%;
          > div:nth-child(1) {
            text-align: center;
            color: #dfedff;
            margin-bottom: 5px;
          }
          > div:nth-child(2) {
            display: flex;
            align-items: center;
            padding: 0 16px;
            .el-cascader {
              .el-input .el-icon-arrow-down::before {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                margin-top: 5px;
                border: 5px solid #83bafe;
                border-bottom-color: transparent;
                border-right-color: transparent;
                border-top-color: #83bafe;
                border-left-color: transparent;
              }
              .el-input__inner {
                background-color: #042646;
                border: none;
                color: #bedbff;
                &::placeholder {
                  color: #bedbff;
                }
              }
            }
            > div:nth-child(2) {
              color: #adccfb;
              font-size: 18px;
              margin-left: 10px;
            }
          }
        }
      }
      // 订单列表
      > .orderList {
        padding-top: 15px;
        overflow-y: auto;
        background: url("../assets/image/listBg.png") no-repeat;
        background-size: 100% 100%;
        > div:nth-child(1) {
          text-align: center;
          color: #dfedff;
          margin-bottom: 17px;
        }
        > .list {
          padding: 0 16px;
          > .item {
            margin-bottom: 25px;
            > .info {
              display: flex;
              align-items: center;
              > span {
                display: block;
                font-size: 14px;
              }
              > span:nth-child(1) {
                width: 30%;
                white-space: nowrap;
                color: #e6f7ff;
              }
              > span:nth-child(2) {
                max-width: 180px;
                color: #f2ca9e;
                cursor: pointer;
              }
              > span:nth-child(3) {
                color: #e6f7ff;
                margin-left: auto;
              }
            }
            > .el-progress {
              width: 100%;
              height: 10px;
              margin-top: 5px;
              > .el-progress-bar {
                height: 10px;
                > .el-progress-bar__outer {
                  height: 10px !important;
                  border-radius: 0;
                  > .el-progress-bar__inner {
                    background-color: unset;
                    background-image: linear-gradient(
                      to right,
                      #4bb3d2,
                      #3ed6bc,
                      #36eaaf
                    );
                    border-radius: 0;
                  }
                }
              }
            }
          }
        }
      }
      // 列表滚动条
      .orderList::-webkit-scrollbar {
        // 宽高分别对应横竖滚动条的尺寸
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }
      .orderList::-webkit-scrollbar-thumb {
        // 滚动条小方块
        border-radius: 4px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #d8d8d8;
      }
      .orderList::-webkit-scrollbar-track {
        // 滚动条轨道
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        background: transparent;
      }
    }
    > .right {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 79%;
      height: 100%;
      background: url("../assets/image/border.png") no-repeat;
      background-size: 100% 100%;
      // 地图
      > #containers {
        height: 97%;
        width: 99%;
      }
      // 地图左上角的数字
      > .nums {
        position: absolute;
        top: 3%;
        left: 3%;
        display: flex;
        > .item {
          width: 120px;
          height: 120px;
          background: url("@/assets/image/numsBg.png") no-repeat;
          background-size: 100% 100%;
          text-align: center;
          margin-right: 35px;
          > .num {
            display: block;
            font-size: 40px;
            font-weight: 600;
            height: 85px;
            line-height: 85px;
          }
          > .type {
            display: block;
            font-size: 14px;
            color: #aee4f8;
            height: 35px;
            line-height: 35px;
          }
        }
        > .item:nth-child(1) {
          color: #ffb14b;
        }
        > .item:nth-child(2) {
          color: #ff8489;
        }
        > .ing {
          color: #b1cffc !important;
        }
      }
      // 鼠标悬浮订单列表的订单编号
      > .orderDetailModal {
        width: 30%;
        position: absolute;
        top: 50%;
        left: 3%;
        > .top {
          display: flex;
          padding: 6px 16px;
          background-color: #050b1a;
          margin-bottom: 20px;
          > span {
            display: block;
            color: #ffb14b;
            font-size: 16px;
          }
          > span:nth-child(1) {
            color: #b1cffc;
            font-size: 18px;
          }
          > span:nth-child(2) {
            margin-left: auto;
            margin-right: 7px;
          }
        }
        > .info {
          padding: 10px 16px;
          background-color: #050b1a;
          color: #fff;
          font-size: 14px;
          > .time {
            display: flex;
            align-items: center;
            margin-bottom: 7px;
            > span {
              display: block;
            }
            > span:nth-child(1) {
              margin-right: 20px;
            }
          }
          > .address {
            margin-bottom: 14px;
            > .item {
              display: flex;
              align-items: center;
              > span:nth-child(1) {
                display: block;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                line-height: 18px;
                text-align: center;
                margin-right: 10px;
              }
              > span:nth-child(2) {
                font-size: 16px;
              }
            }
            > .start {
              margin-bottom: 12px;
              > span:nth-child(1) {
                background-color: #06bf81;
              }
            }
            > .end {
              > span:nth-child(1) {
                background-color: #f96075;
              }
            }
          }
          > .infoNum {
            margin-bottom: 10px;
            > div:nth-child(1) {
              margin-bottom: 9px;
            }
            > div {
              > span:nth-child(1) {
                margin-right: 60px;
              }
            }
          }
          > .state {
            > span {
              display: block;
              font-size: 16px;
              color: #ffb14b;
            }
          }
        }
      }
    }
  }
}

// 悬浮标记点弹窗
.map_mouserBox {
  width: 200px;
  > .name {
    padding: 6px 16px;
    background-color: #050b1a;
    margin-bottom: 20px;
    font-size: 18px;
    color: #aee4f8;
  }
  > .map_mouserBox_content {
    padding: 12px;
    color: #bedbff;
    font-size: 14px;
    background-color: #050b1a;
    > div {
      margin-bottom: 10px;
    }
    > div:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
