import { getSiteType, setSiteType, setToken, getToken } from "@/utils/auth";

const getDefaultState = () => {
  return {
    sitesType: getSiteType(),
    token: getToken(),
  };
};

const state = getDefaultState();

const mutations = {
  SET_SITE_TYPE: (state, type) => {
    state.siteType = type;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
};

const actions = {
  changeSiteType({ commit }, data) {
    return new Promise((resolve) => {
      const { token, type } = data;
      console.log(type);
      console.log(token);
      commit("SET_SITE_TYPE", type);
      commit("SET_TOKEN", token);
      setToken(token);
      setSiteType(type);
      setTimeout(() => {
        resolve(true);
      }, 500);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
