import request from "@/utils/request";
import store from "@/store";
let controllerName;
if (store.getters.sitesTyp === "1") {
  controllerName = "Enbusiness";
} else {
  controllerName = "Business";
}

// 获取头部筛选条件
export function getSetting(data) {
  return request({
    url: `${controllerName}/Unload/filterTitle`,
    method: "post",
    data,
  });
}

// 条件筛选
export function screen(data) {
  return request({
    url: `${controllerName}/Unload/order`,
    method: "post",
    data,
  });
}
