<template>
  <div class="host-body">
    <div class="title">
      <div class="left">
        <img src="@/assets/image/left.png" alt="" />
        <div class="content">
          <div v-if="!showBack">
            <div class="pr default-button" @click="backTop">
              <img
                src="../assets/image/button.png"
                class="h-100 w-100 pa"
                alt=""
              />
              <span class="pr">返回上一级</span>
            </div>
            <div class="pr default-button" @click="jump('Home')">
              <img
                src="../assets/image/button.png"
                class="h-100 w-100 pa"
                alt=""
              />
              <span
                :style="{ color: isHome ? '#B1CFFC' : '#6681A8' }"
                class="pr"
                >首页</span
              >
            </div>
            <div class="pr default-button" @click="jump('Order')">
              <img
                src="../assets/image/button.png"
                class="h-100 w-100 pa"
                alt=""
              />
              <span
                :style="{ color: !isHome ? '#B1CFFC' : '#6681A8' }"
                class="pr"
                >订单数据</span
              >
            </div>
          </div>
          <div v-else>
            <div class="pr default-button" @click="back">
              <img
                src="../assets/image/button.png"
                class="h-100 w-100 pa"
                alt=""
              />
              <span class="pr">返回</span>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <img src="@/assets/image/title.png" alt="" />
        <div class="content">
          <span v-show="!editTitle" @dblclick="editTitle = true">{{
            input
          }}</span>
          <el-input
            v-show="editTitle"
            class="h-100"
            @blur="editName"
            v-model="input"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="right">
        <img src="@/assets/image/left.png" alt="" />
        <div class="content">
          <div class="pr default-button">
            <img
              src="../assets/image/button.png"
              class="h-100 w-100 pa"
              alt=""
            />
            <span class="pr">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from "@/utils";
import { baseInfo, editShowName } from "@/api/panel";
import store from "@/store";

export default {
  data() {
    return {
      menuList: [
        {
          name: "首页",
          id: 1,
        },
        {
          name: "业务分布",
          id: 2,
        },
        {
          name: "用户数据",
          id: 3,
        },
        {
          name: "平台车辆",
          id: 4,
        },
        {
          name: "料场订单",
          id: 5,
        },
        {
          name: "调度中心",
          id: 6,
        },
      ],
      input: "运输车辆可视化系统",
      dateYear: "1970-01-01",
      dateWeek: "周一",
      dateDay: "00:00:01",
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      activeMenu: 1,
      editTitle: false,
      showBack: true,
      isHome: true,
    };
  },
  watch: {
    "$store.state.activeMenu": {
      handler(id) {
        this.activeMenu = id;
      },
      deep: true,
    },
    $route: {
      handler: function (route) {
        console.log("跳转回参数页面", route.name);
        if (route.name === "Home") {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
        // this.redirect = route.query && route.query.redirect;
        if (route.name === "OrderDetail") {
          this.showBack = true;
        } else {
          this.showBack = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.timeFn();
    this.getData();
  },
  mounted() {},
  unMounted() {
    clearInterval(this.timing);
  },
  methods: {
    backTop() {
      if (store.getters.sitesTyp === "1") {
        window.location.href = "http://csadbmix.shiliaobang.cn/";
      } else {
        window.location.href = "http://csbmix.shiliaobang.cn/";
      }
    },
    back() {
      this.$router.back();
    },
    jump(name) {
      this.$router.push({
        name: name,
      });
    },
    getData() {
      console.log(this.$store.getters.sitesTyp);
      console.log("this.$store.getters.sitesTyp");
      if (!this.$route.query.t) {
        baseInfo().then((res) => {
          // console.log(res);
          this.input = res.data.name;
        });
      }
    },
    editName() {
      editShowName({ name: this.input }).then((res) => {
        console.log(res);
        this.editTitle = false;
        this.getData();
      });
    },
    changeMenu(e) {
      // this.activeMenu = e
      this.$store.commit("changeMenu", e);
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.host-body {
  .title {
    height: 145px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 24px;
    overflow: hidden;

    .el-input__inner {
      border: none;
      background: none;
      font-size: 36px;
      text-align: center;
    }

    & > div {
      width: 33.3%;

      img {
        width: 100%;
      }
    }

    .left {
      //display: flex;
      position: relative;
      flex-shrink: 0;

      .content {
        position: absolute;
        top: 0;

        & > div {
          display: flex;

          & > div {
            margin-right: 23px;
          }
        }
      }
    }

    .center {
      flex-shrink: 0;
      font-size: 36px;
      position: relative;
      width: 800px;
      left: -70px;

      .content {
        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        top: calc(50% - 15px);
        transform: translateY(-50%);
      }
    }

    .right {
      flex-shrink: 0;
      position: relative;
      //width: calc(33.3% + 50px);
      left: -140px;

      img {
        transform: rotateY(180deg);
      }

      .content {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        transform: translate(-25%, -50%);
      }
    }
  }
}
</style>
