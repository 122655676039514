import request from "@/utils/request";
import store from "@/store";
let controllerName;
if (store.getters.sitesTyp === "1") {
  controllerName = "Enbusiness";
} else {
  controllerName = "Business";
}
export function getPanelData(data) {
  return request({
    url: `${controllerName}/Unload/index`,
    method: "post",
    data,
  });
}
export function getfilterTitle(data) {
  return request({
    url: `${controllerName}/Unload/filterTitle`,
    method: "post",
    data,
  });
}
export function editShowName(data) {
  return request({
    url: `${controllerName}/Unload/editShowName`,
    method: "post",
    data,
  });
}
export function baseInfo(data) {
  return request({
    url: `${controllerName}/Unload/baseInfo`,
    method: "post",
    data,
  });
}
export function location(data) {
  return request({
    url: `${controllerName}/Unload/location`,
    method: "post",
    data,
  });
}
