const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  menus: (state) => state.user.menus,
  list: (state) => state.user.list,
  sites: (state) => state.user.sites || -1,
  bmixs: (state) => state.user.bmixs || "",
  time: (state) => state.app.times,
  backRouter: (state) => state.app.backRouter,
  token: (state) => state.apiType.token,
  sitesTyp: (state) => state.apiType.sitesType,
};
export default getters;
