import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 600000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers["en_token"] = getToken();
    config.headers["b_token"] = getToken();
    /*    console.log(store.getters.token);
    if (store.getters.token) {
      if (store.getters.sitesTyp === "1") {
        config.headers["en_token"] = getToken();
      } else {
        config.headers["b_token"] = getToken();
      }

      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
    }*/
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200 && res.errid !== 1) {
      Message({
        message: res.msg || "服务器异常，请重试",
        type: "error",
        duration: 5 * 1000,
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (
        res.code === 50008 ||
        res.code === 50012 ||
        res.code === 50014 ||
        res.code === -100
      ) {
        // to re-login
        MessageBox.alert("您的登录凭证已过期，请重新登录", "重新登录", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          if (store.getters.sitesTyp === "1") {
            location.href = "http://adbmix.shiliaobang.cn/";
          } else {
            location.href = "http://bmix.shiliaobang.cn/";
          }
        });
      }
      return Promise.reject(new Error(res.message || "服务器异常，请重试"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
