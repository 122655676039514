import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/style/style.scss";
import dataV from "@jiaminghi/data-view";
import ElementUI from "element-ui";
import "./assets/style/element-variables.scss";
// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.use(dataV);
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
